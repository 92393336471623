import { IMasonryImagesListItem } from '~/features/main-landing/interfaces/masonry-images-list-item.interface';

export const masonryImagesList: IMasonryImagesListItem[] = [
  {
    path: require('~/assets/img/main/masonry/0.png'),
    mobPath: require('~/assets/img/main/masonry/0-small.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/0.webp'),
    mobPathWebp: require('~/assets/img/main/masonry/webp/0-small.webp'),
    alt: 'a painting with some flowers on it',
    altMob: 'a collage of two paintings with flowers on them',
  },
  {
    path: require('~/assets/img/main/masonry/1.png'),
    mobPath: require('~/assets/img/main/masonry/1-small.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/1.webp'),
    mobPathWebp: require('~/assets/img/main/masonry/webp/1-small.webp'),
    alt: 'a collage of two paintings of a man and a horse',
    altMob: 'a collage of three paintings of a women, a leopard and a women with a bird',
  },
  {
    path: require('~/assets/img/main/masonry/2.png'),
    mobPath: require('~/assets/img/main/masonry/2-small.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/2.webp'),
    mobPathWebp: require('~/assets/img/main/masonry/webp/2-small.webp'),
    alt: 'a collage of two paintings with flowers on them',
    altMob: 'a collage of two paintings of a woman and bears',
  },
  {
    path: require('~/assets/img/main/masonry/3.png'),
    mobPath: require('~/assets/img/main/masonry/3-small.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/3.webp'),
    mobPathWebp: require('~/assets/img/main/masonry/webp/3-small.webp'),
    alt: 'a collage of three paintings of a women, a leopard and a women with a bird',
    altMob: 'a collage of two paintings of a woman and a rooster',
  },
  {
    path: require('~/assets/img/main/masonry/4.png'),
    mobPath: require('~/assets/img/main/masonry/4-small.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/4.webp'),
    mobPathWebp: require('~/assets/img/main/masonry/webp/4-small.webp'),
    alt: 'a collage of two paintings of a men and bears',
    altMob: 'a collage of two paintings of a woman and a unicorn',
  },
  {
    path: require('~/assets/img/main/masonry/5.png'),
    mobPath: require('~/assets/img/1x1.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/5.webp'),
    mobPathWebp: require('~/assets/img/1x1.webp'),
    alt: 'a collage of two paintings of a woman and a rooster',
    altMob: '1x1 pixel photo',
  },
  {
    path: require('~/assets/img/main/masonry/6.png'),
    mobPath: require('~/assets/img/1x1.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/6.webp'),
    mobPathWebp: require('~/assets/img/1x1.webp'),
    alt: 'a clollage of two paintings of a woman and a unicorn',
    altMob: '1x1 pixel photo',
  },
  {
    path: require('~/assets/img/main/masonry/7.png'),
    mobPath: require('~/assets/img/1x1.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/7.webp'),
    mobPathWebp: require('~/assets/img/1x1.webp'),
    alt: 'a collage of three paintings: a women with a bird, a woman and puma and a flying horse',
    altMob: '1x1 pixel photo',
  },
  {
    path: require('~/assets/img/main/masonry/8.png'),
    mobPath: require('~/assets/img/1x1.png'),
    pathWebp: require('~/assets/img/main/masonry/webp/8.webp'),
    mobPathWebp: require('~/assets/img/1x1.webp'),
    alt: 'a collage of two paintings of a woman and bears',
    altMob: '1x1 pixel photo',
  },
];
