import { FRONTEND_MAIN_DOMAIN } from '~/shared/constants';

interface IGetSeoTagsParams {
  title: string;
  description: string;
  image?: string;
  path?: string;
}

export const getSeoTags = (params: IGetSeoTagsParams): any[] => [
  {
    hid: 'description',
    name: 'description',
    content: params.description,
  },

  // Facebook Meta Tags
  {
    hid: 'og:url',
    property: 'og:url',
    content: params.path ? `${FRONTEND_MAIN_DOMAIN}${params.path}` : FRONTEND_MAIN_DOMAIN,
  },
  {
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  },
  {
    hid: 'og:title',
    property: 'og:title',
    content: params.title,
  },
  {
    hid: 'og:description',
    property: 'og:description',
    content: params.description,
  },
  {
    hid: 'og:image',
    property: 'og:image',
    // TODO: Paste path of static image
    content: params.image || '/og-image.jpg',
  },
  //  Twitter Meta Tags
  {
    hid: 'twitter:card',
    property: 'twitter:card',
    content: 'summary_large_image',
  },
  {
    hid: 'twitter:domain',
    property: 'twitter:domain',
    content: 'artsocial.com',
  },
  {
    hid: 'twitter:url',
    property: 'twitter:url',
    content: params.path ? `${FRONTEND_MAIN_DOMAIN}${params.path}` : FRONTEND_MAIN_DOMAIN,
  },
  {
    hid: 'twitter:title',
    property: 'twitter:title',
    content: params.title,
  },
  {
    hid: 'twitter:description',
    property: 'twitter:description',
    content: params.description,
  },
  {
    hid: 'twitter:image',
    property: 'twitter:image',
    // TODO: Paste path of static image
    content: params.image || '/og-image.jpg',
  },
];
