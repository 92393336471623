























import { defineComponent } from '@nuxtjs/composition-api';
import { masonryImagesList } from '~/features/main-landing/config/masonry-images-list.config';

export default defineComponent({
  name: 'MainLandingMasonry',
  setup() {
    return { masonryImagesList };
  },
});
