import { IFeedbacksListItem } from '~/features/main-landing/interfaces/feedbacks-list-item.interface';

export const feedbacksList: IFeedbacksListItem[] = [
  {
    text: 'mainLanding.feedbacks.1.text',
    name: 'mainLanding.feedbacks.1.name',
    position: 'mainLanding.feedbacks.1.position',
  },
  {
    text: 'mainLanding.feedbacks.2.text',
    name: 'mainLanding.feedbacks.2.name',
  },
  {
    text: 'mainLanding.feedbacks.3.text',
    name: 'mainLanding.feedbacks.3.name',
  },
  {
    text: 'mainLanding.feedbacks.4.text',
    name: 'mainLanding.feedbacks.4.name',
  },
];
