import { render, staticRenderFns } from "./MainLandingPlatformFeatures.vue?vue&type=template&id=31921b43&scoped=true&"
import script from "./MainLandingPlatformFeatures.vue?vue&type=script&lang=ts&"
export * from "./MainLandingPlatformFeatures.vue?vue&type=script&lang=ts&"
import style0 from "./MainLandingPlatformFeatures.vue?vue&type=style&index=0&id=31921b43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31921b43",
  null
  
)

export default component.exports