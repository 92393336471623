

























import { defineComponent } from '@nuxtjs/composition-api';
import UiButton from '~/shared/components/UiButton/index.vue';
import MainLandingRegistrationForm from '~/features/main-landing/components/MainLandingRegistrationForm.vue';
import MainLandingMasonry from '~/features/main-landing/components/MainLandingMasonry.vue';
import { scrollToAnchor } from '~/utils/scroll-to-anchor';

export default defineComponent({
  name: 'MainLandingHero',
  components: { MainLandingMasonry, MainLandingRegistrationForm, UiButton },
  methods: {
    onExploreClick(): void {
      scrollToAnchor('features');
    },
  },
});
