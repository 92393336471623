























import { defineComponent } from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';
import PublicLayout from '~/shared/components/layouts/public-layout/index.vue';
import { getSeoTags } from '~/utils/get-seo-tags';
import { isWebview } from '~/utils/is-webview';
import MainLandingHero from '~/features/main-landing/components/MainLandingHero.vue';
import MainLandingArtClubMembership from '~/features/main-landing/components/MainLandingArtClubMembership.vue';
import MainLandingFeedbacks from '~/features/main-landing/components/MainLandingFeedbacks.vue';
import MainLandingBottomRegistration from '~/features/main-landing/components/MainLandingBottomRegistration.vue';
import BaseFooter from '~/shared/components/layouts/parts/BaseFooter.vue';
import MainLandingPlatformFeatures from '~/features/main-landing/components/MainLandingPlatformFeatures.vue';

export default defineComponent({
  name: 'IndexLanding',
  components: {
    MainLandingPlatformFeatures,
    BaseFooter,
    MainLandingBottomRegistration,
    MainLandingFeedbacks,
    MainLandingArtClubMembership,
    MainLandingHero,
    PublicLayout,
  },
  layout: 'public',
  auth: 'guest',
  middleware: [
    'static-pages-last-modified',
    ({ redirect, localePath, $auth }: Context): void => {
      if (!process.client) return;

      if (isWebview() && !$auth.loggedIn) redirect(localePath('/feed'));
    },
  ],
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const seoTags = getSeoTags({
      title: this.$tc('onBoarding.meta.title'),
      description: this.$tc('onBoarding.meta.description'),
    });

    return {
      title: this.$tc('onBoarding.meta.title'),
      titleTemplate: '',
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      meta: [...i18nHead.meta, ...seoTags],
      link: [...i18nHead.link],
    };
  },
  methods: {
    onLoginClick(): void {
      this.$bus.emit('open-login');
    },

    onRegisterClick(): void {
      this.$bus.emit('open-register');
    },
  },
});
