
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { isNil } from 'ramda';
import { blurHashToDataURL } from '~/utils/blurhashToDataURL';
import { IFileExtras } from '~/features/files/interfaces/file.interface';
import { BLURHASH_DEFAULT_RESOLUTION } from '~/shared/constants';

export interface INuxtProgressiveImgLoadResponse {
  placeholder: string;
  src: string;
}

export default defineComponent({
  name: 'NuxtProgressiveImg',
  inheritAttrs: false,
  props: {
    provider: {
      type: String,
      default: 'custom',
    },
    fit: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
      required: true,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
    extras: {
      type: Object as PropType<IFileExtras>,
      default: undefined,
    },
    withAspectRatio: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load', 'error', 'intersect'],
  data() {
    return {
      observer: (null as unknown) as IntersectionObserver,
      intersected: false,
      loaded: false,
    };
  },
  computed: {
    srcPlaceholder(): string {
      if (isNil(this?.extras?.thumbnail?.blurhash)) return '';
      const maxResolutionParam = Math.max(
        this?.extras?.imageSize?.width,
        this?.extras?.imageSize?.height,
      );
      const coefficient = maxResolutionParam / BLURHASH_DEFAULT_RESOLUTION;

      return blurHashToDataURL({
        hash: this?.extras?.thumbnail?.blurhash,
        width: Math.round(this?.extras?.imageSize?.width / coefficient),
        height: Math.round(this?.extras?.imageSize?.height / coefficient),
      }) as string;
    },
    srcImage(): string {
      const src = this.$img(
        this.src,
        { width: this.width as number, height: this.height as number, fit: this.fit as any },
        { provider: this.provider },
      );

      return this.intersected && this.src ? src : this.srcPlaceholder || src;
    },

    aspectRatio(): string {
      if (isNil(this?.extras?.imageSize?.height) || isNil(this?.extras?.imageSize?.width))
        return '';

      return `${this?.extras?.imageSize?.width} / ${this?.extras?.imageSize?.height}`;
    },
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((entries) => {
        const image = entries[0];

        if (image.isIntersecting) {
          this.intersected = true;
          this.observer.disconnect();
          this.$emit('intersect');
        }
      }, {});

      this.observer.observe(this.$el);
    }
  },
  destroyed() {
    if ('IntersectionObserver' in window) this.observer.disconnect();
  },
  methods: {
    load(): void {
      if (this.$el && this.$el.getAttribute('src') !== this.srcPlaceholder) {
        this.loaded = true;

        this.$emit('load', {
          placeholder: this.srcPlaceholder,
          src: this.srcImage,
        } as INuxtProgressiveImgLoadResponse);
      }
    },

    error(): void {
      this.$emit('error', this.$el);
    },
  },
  render(h) {
    return h('img', {
      attrs: {
        src: this.srcImage,
        style: this.withAspectRatio ? `aspect-ratio: ${this.aspectRatio};` : undefined,
      },
      domProps: this.$attrs,
      on: { load: this.load, error: this.error },
    });
  },
});
