














































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from '@nuxtjs/composition-api';
import { Options, Splide } from '@splidejs/splide';
import { AutoScrollComponent } from '@splidejs/splide-extension-auto-scroll';
import { Intersection } from '@splidejs/splide-extension-intersection';
import { isNil } from 'ramda';
import { IUiImagesGalleryItem } from '~/shared/interfaces/ui-images-gallery-item.interface';
import { useOpenPhotoSwipe } from '~/shared/composable/useOpenPhotoSwipe';
import { AutoScroll } from '~/shared/components/post-item/CustomAutoScroll';
import { IFeaturesSliderItem } from '~/features/main-landing/interfaces/features-list-item.interface';
import NuxtProgressiveImg from '~/shared/components/NuxtProgressiveImg.vue';
import type { INuxtProgressiveImgLoadResponse } from '~/shared/components/NuxtProgressiveImg.vue';
import { IPostFile } from '~/features/profile/interfaces/post-file.interface';
import { IArtSale } from '~/features/art-sales/interfaces/art-sale.interface';

const options: Options = {
  type: 'loop',
  autoWidth: true,
  drag: 'free',
  arrows: false,
  clones: 0,
  autoScroll: {
    speed: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  },
  intersection: {
    inView: {
      autoScroll: true,
    },
    outView: {
      autoScroll: false,
    },
  },
};

export default defineComponent({
  name: 'PostItemImagesSlider',
  components: {
    SaleBadge: () => import('~/features/art-sales/components/SaleBadge.vue'),
    NuxtProgressiveImg,
  },
  props: {
    images: {
      type: Array as PropType<Partial<IFeaturesSliderItem & IPostFile>[]>,
      required: true,
      default: () => [],
    },
    artSalePost: {
      type: Object as PropType<IArtSale>,
      default: undefined,
    },
    isLightboxEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { openPhotoswipe } = useOpenPhotoSwipe();
    const setStopAfterDragged = ref(false);
    const draggedTimeout = ref(null as null | ReturnType<typeof setTimeout>);

    let splide = (null as unknown) as Splide;
    const slider = ref((null as unknown) as HTMLDivElement);
    const isSliderShown = computed(() => props.images?.length > 1);

    const mountSlider = (): void => {
      splide = new Splide(slider.value, options);

      splide.mount({ AutoScroll, Intersection });

      splide.on('dragging', () => {
        clearDraggingTimeout();

        setStopAfterDragged.value = true;
      });

      splide.on('dragged', () => {
        ctx.emit('dragged');

        if (!setStopAfterDragged.value) return;

        setStopAfterDragged.value = false;

        pauseSlider();

        draggedTimeout.value = setTimeout(() => {
          playSlider();
        }, 2000);
      });
    };

    const clearDraggingTimeout = (): void => {
      clearTimeout(draggedTimeout.value as ReturnType<typeof setTimeout>);
    };

    const pauseSlider = (): void => {
      if (!isSliderShown.value) return;

      (splide.Components.AutoScroll as AutoScrollComponent).pause();
    };

    const playSlider = (): void => {
      if (!isSliderShown.value) return;

      (splide.Components.AutoScroll as AutoScrollComponent)?.play();
    };

    onMounted(() => {
      if (!process.server && isSliderShown.value) mountSlider();
    });

    onBeforeUnmount(() => {
      splide?.destroy();
    });

    return {
      openPhotoswipe,
      slider,
      isSliderShown,
      playSlider,
      pauseSlider,
      clearDraggingTimeout,
    };
  },
  computed: {
    compoundImages(): IUiImagesGalleryItem[] {
      return this.images.map((i) => {
        const imageSize = i.extras?.imageSize;

        return {
          src: this.$img(i.url as string, { width: 1000, height: 1000 }, { provider: 'custom' }),
          width: imageSize?.width,
          height: imageSize?.height,
        };
      });
    },
    isArtSalePost(): boolean {
      return !isNil(this.artSalePost);
    },
    isSold(): boolean {
      if (!this.isArtSalePost) return false;

      return this.artSalePost?.isSold;
    },
  },
  beforeDestroy() {
    this.clearDraggingTimeout();
  },
  methods: {
    onImageClick(index: number): void {
      if (!this.isLightboxEnabled) return;

      this.openPhotoswipe({ items: this.compoundImages, index });
    },

    onImageLoad(payload: INuxtProgressiveImgLoadResponse): void {
      // NOTE: This hack was made because Splide module makes html copy of our NuxtProgressiveImg component that
      // doesn't watch the state and doesn't update image src.
      const imagesList = (this.$refs.slider as HTMLDivElement).querySelectorAll('img')!;

      [...imagesList].forEach((el) => {
        if (el.src !== payload.placeholder) return;

        el.src = payload.src;
        el.classList.remove('tw-blur-sm');
      });
    },
  },
});
