



























import { defineComponent } from '@nuxtjs/composition-api';
import MainLandingRegistrationForm from '~/features/main-landing/components/MainLandingRegistrationForm.vue';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';

export default defineComponent({
  name: 'MainLandingBottomRegistration',
  components: { UiAdaptive, MainLandingRegistrationForm },
});
