import { IPostCategory, IPostCategoryForFilter } from '~/shared/interfaces/post-category.interface';
import { EPostCategoryId } from '~/shared/enums/post-category-id.enum';

export const postCategoriesList: IPostCategory[] = [
  {
    id: EPostCategoryId.General,
    label: 'common.postCategory.general.title',
    description: 'common.postCategory.general.description',
    imagePath: require('~/assets/img/icons/post-categories/general.svg'),
    isGeneral: true,
    icon: 'category-general',
    badgeColor: '#E2E2EA',
    iconColor: '#72727F',
  },
  {
    id: EPostCategoryId.FinishedArt,
    label: 'common.postCategory.finishedArt.title',
    description: 'common.postCategory.finishedArt.description',
    imagePath: require('~/assets/img/icons/post-categories/finished-art.svg'),
    icon: 'category-finished-art',
    badgeColor: '#3D82EB',
  },
  {
    id: EPostCategoryId.Critique,
    label: 'common.postCategory.critique.title',
    description: 'common.postCategory.critique.description',
    imagePath: require('~/assets/img/icons/post-categories/critique.svg'),
    icon: 'category-critique',
    badgeColor: '#FFA71D',
  },
  {
    id: EPostCategoryId.Discussion,
    label: 'common.postCategory.discussion.title',
    description: 'common.postCategory.discussion.description',
    imagePath: require('~/assets/img/icons/post-categories/discussion.svg'),
    icon: 'category-discussion',
    badgeColor: '#A373E1',
  },
  {
    id: EPostCategoryId.Announcement,
    label: 'common.postCategory.announcement.title',
    description: 'common.postCategory.announcement.description',
    imagePath: require('~/assets/img/icons/post-categories/announcement.svg'),
    icon: 'category-announcement',
    badgeColor: '#F84794',
  },
  {
    id: EPostCategoryId.ArtSale,
    label: 'common.postCategory.artSale.title',
    description: 'common.postCategory.artSale.description',
    imagePath: require('~/assets/img/icons/post-categories/art-sale.svg'),
    icon: 'category-art-sale',
    badgeColor: '#FC5A5A',
    isIgnoredInCommunity: true,
  },
  {
    id: EPostCategoryId.ArtSold,
    label: 'common.postCategory.artSold.title',
    description: 'common.postCategory.artSale.description',
    icon: 'category-art-sale',
    badgeColor: '#92929D',
    isIgnoredInCreatePostFlow: true,
    isIgnoredInCommunity: true,
  },
];

export const postCategoriesMap: Record<EPostCategoryId, IPostCategory> = Object.fromEntries([
  ...postCategoriesList.map((i) => [i.id, i]),
]);

export const postCategoriesListForFilters: IPostCategoryForFilter[] = [
  {
    id: '',
    label: 'common.postCategory.all.title',
    icon: 'category-all',
  },
  ...postCategoriesList.map((i) => ({
    id: i.id,
    label: i.label,
    icon: i.icon,
    isIgnoredInCreatePostFlow: i.isIgnoredInCreatePostFlow,
  })),
];
