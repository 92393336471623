












































import { defineComponent } from '@nuxtjs/composition-api';
import { Splide } from '@splidejs/splide';
import { featuresList } from '~/features/main-landing/config/features-list.config';
import PostItemImagesSlider from '~/shared/components/post-item/PostItemImagesSlider.vue';
import PostCategoryBadge from '~/shared/components/post-item/PostCategoryBadge.vue';
import { EPostCategoryId } from '~/shared/enums/post-category-id.enum';

export default defineComponent({
  name: 'MainLandingPlatformFeaturesSlider',
  components: { PostCategoryBadge, PostItemImagesSlider },
  emits: ['change'],
  setup() {
    return {
      featuresList,
      EPostCategoryId,
    };
  },
  data() {
    return {
      slider: (null as unknown) as Splide,
    };
  },
  mounted() {
    this.mountSlider();
  },
  beforeDestroy() {
    this.removeHandlers();
  },
  methods: {
    changeSlide(index: number): void {
      this.slider.go(index);
    },

    mountSlider(): void {
      this.slider = new Splide(this.$refs.slider as HTMLDivElement, {
        type: 'fade',
        rewind: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        speed: 500,
        interval: 4000,
        perPage: 1,
        pagination: false,
        arrows: false,
        breakpoints: {
          767: {
            arrows: true,
            pagination: true,
          },
        },
      }).mount();

      this.slider.on('move', (newIndex) => {
        this.$emit('change', newIndex);
      });
    },

    removeHandlers(): void {
      this.slider.off('move');
    },
  },
});
