import { IFeaturesListItem } from '~/features/main-landing/interfaces/features-list-item.interface';

export const featuresList: IFeaturesListItem[] = [
  {
    imagePath: require('~/assets/img/main/features/0.jpg'),
    imagePathWebp: require('~/assets/img/main/features/webp/0.webp'),
    text: 'mainLanding.features.slider.0',
    alt: 'a screenshot of a feed post with sliding photo carousel',
    slider: [
      {
        id: '0',
        url: require('~/assets/img/main/features/slider/0.jpg'),
        alt: 'a painting of flowers and a body of water.',
      },
      {
        id: '1',
        url: require('~/assets/img/main/features/slider/1.jpg'),
        alt: 'a painting of a pond with flowers and trees.',
      },
      {
        id: '2',
        url: require('~/assets/img/main/features/slider/2.jpg'),
        alt: 'a painting of yellow flowers in a forest.',
      },
    ],
  },
  {
    imagePath: require('~/assets/img/main/features/1.jpg'),
    imagePathWebp: require('~/assets/img/main/features/webp/1.webp'),
    text: 'mainLanding.features.slider.1',
    alt: 'a screenshot of a cell phone with an art portfolio',
  },
  {
    imagePath: require('~/assets/img/main/features/2.jpg'),
    imagePathWebp: require('~/assets/img/main/features/webp/2.webp'),
    text: 'mainLanding.features.slider.2',
    alt: 'a screenshot of a cell phone with chats on the screen',
  },
  {
    imagePath: require('~/assets/img/main/features/3.jpg'),
    imagePathWebp: require('~/assets/img/main/features/webp/3.webp'),
    text: 'mainLanding.features.slider.3',
    alt: 'a screenshot of a text comments on a mobile phone',
  },
  {
    imagePath: require('~/assets/img/main/features/4.jpg'),
    imagePathWebp: require('~/assets/img/main/features/webp/4.webp'),
    text: 'mainLanding.features.slider.4',
    alt:
      'a screenshot of a cell phone with a list of 5 categories for posts: general, finished art, critique, discussion, announcement',
  },
];
