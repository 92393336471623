




















































import { defineComponent } from '@nuxtjs/composition-api';
import {
  socialFooterShortenLinks,
  socialFooterSocialsLinks,
} from '~/shared/config/social-footer.config';
import ContactUsModal from '~/shared/components/modals/ContactUsModal.vue';
import StoreBadges from '~/shared/components/layouts/parts/StoreBadges.vue';
import { isWebview } from '~/utils/is-webview';

export default defineComponent({
  name: 'BaseFooter',
  components: { StoreBadges, ContactUsModal },
  props: {},
  data() {
    return {
      socialFooterSocialsLinks,
      socialFooterShortenLinks,

      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isStoreBadgesShown(): boolean {
      return !isWebview();
    },
  },
  methods: {
    onContactUsClick(): void {
      (this.$refs.contactUsModal as InstanceType<typeof ContactUsModal>).show();
    },
  },
});
