


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { IPostCategory } from '~/shared/interfaces/post-category.interface';
import { postCategoriesMap } from '~/shared/config/post-categories.config';
import { EPostCategoryId } from '~/shared/enums/post-category-id.enum';

export default defineComponent({
  name: 'PostCategoryBadge',
  props: {
    categoryId: {
      type: String as PropType<EPostCategoryId>,
      required: true,
      default: () => ({}),
    },
    isMinimized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTextHidden: this.isMinimized,
      statusTimeout: null as ReturnType<typeof setTimeout> | null,
      observer: null as IntersectionObserver | null,
    };
  },
  computed: {
    categoryConfig(): IPostCategory {
      return postCategoriesMap[this.categoryId];
    },
  },
  mounted() {
    if (!this.isMinimized) return;

    this.setObserver();
  },
  beforeDestroy() {
    if (!this.isMinimized) return;

    this.unsetObserver();
    clearTimeout(this.statusTimeout as ReturnType<typeof setTimeout>);
  },
  methods: {
    setObserver(): void {
      const handleIntersection = ([entry]: IntersectionObserverEntry[]): void => {
        if (entry.isIntersecting) {
          this.isTextHidden = false;

          setTimeout(() => (this.isTextHidden = true), 3000);
        }
      };

      this.observer = new IntersectionObserver(handleIntersection);

      this.observer.observe(this.$refs.badge as HTMLDivElement);
    },

    unsetObserver(): void {
      this.observer?.unobserve(this.$refs.badge as HTMLDivElement);
    },
  },
});
