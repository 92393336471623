import PhotoSwipe from 'photoswipe';
import type { PhotoSwipeOptions } from 'photoswipe';
import { useRouter } from '@nuxtjs/composition-api';
import { IOpenUiGalleryPayload } from '~/shared/interfaces/open-ui-gallery-payload.interface';

export interface IUseOpenPhotoSwipe {
  openPhotoswipe(payload: IOpenUiGalleryPayload): void;
}

export const useOpenPhotoSwipe = (): IUseOpenPhotoSwipe => {
  const router = useRouter();

  const openPhotoswipe = ({ items, index }: IOpenUiGalleryPayload): void => {
    const options: PhotoSwipeOptions = {
      dataSource: items.length === 2 ? [...items, ...items] : items,
      index,
      spacing: 0.9,
      loop: true,
      counter: false,
      secondaryZoomLevel: 1,
      maxZoomLevel: 2,
    };

    const pswp = new PhotoSwipe(options);

    pswp.on('beforeOpen', async () => {
      const posY = window.scrollY;
      const route = router.currentRoute;

      await router.replace({
        path: route.path,
        query: { ...route.query, lockAppScroll: 'true' },
      });

      window.scrollTo({ left: 0, top: posY });
    });

    pswp.on('destroy', async () => {
      const posY = window.scrollY;
      const route = router.currentRoute;

      await router.replace({
        path: route.path,
        query: { ...route.query, lockAppScroll: undefined },
      });

      window.scrollTo({ left: 0, top: posY });
    });

    pswp.init();
  };

  return { openPhotoswipe };
};
