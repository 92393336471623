







































import { defineComponent } from '@nuxtjs/composition-api';
import { MILAN_ART_APPLE_URL, MILAN_ART_GOOGLE_URL } from '~/shared/constants';

export default defineComponent({
  name: 'MainLandingRegistrationForm',
  components: {
    RegistrationForm: () => import('~/features/auth/components/RegistrationForm.vue'),
  },
  setup() {
    return {
      MILAN_ART_GOOGLE_URL,
      MILAN_ART_APPLE_URL,
    };
  },
  methods: {
    onLoginClick(): void {
      this.$bus.emit('open-login');
    },
  },
});
