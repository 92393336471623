


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TAppLogo } from '~/shared/components/UiAppLogo/config';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import BaseFooter from '~/shared/components/layouts/parts/BaseFooter.vue';
import { TABLET_WIDTH } from '~/shared/constants';

export default defineComponent({
  name: 'PublicLayoutComponent',
  components: {
    BaseFooter,
    BottomBar: () => import('~/shared/components/layouts/parts/menus/BottomBar.vue'),
    UiAdaptive,
    PublicLayoutHeader: () =>
      import('~/shared/components/layouts/public-layout/PublicLayoutHeader.vue'),
  },
  props: {
    headerLogoName: {
      type: String as PropType<TAppLogo>,
      default: 'default',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    showArtClubMenu: {
      type: Boolean,
      default: false,
    },
    headerCustomClass: {
      type: String,
      default: '',
    },
    headerAnimated: {
      type: Boolean,
      default: false,
    },
    headerAnimatedObserveTargetId: {
      type: String,
      default: '',
    },
    showMobileBottomBar: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['login-click', 'register-click', 'header-changed'],
  setup() {
    return {
      TABLET_WIDTH,
    };
  },
  methods: {
    onLoginClick(): void {
      this.$emit('login-click');
    },

    onRegisterClick(): void {
      this.$emit('register-click');
    },
  },
});
