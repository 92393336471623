











































import { defineComponent } from '@nuxtjs/composition-api';
import { artClubFeaturesList } from '~/features/main-landing/config/art-club-features-list.config';
import UiButton from '~/shared/components/UiButton/index.vue';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import { ART_CLUB_SALES_LANDING_LINK } from '~/shared/constants';

export default defineComponent({
  name: 'MainLandingArtClubMembership',
  components: { UiAdaptive, UiButton },
  setup() {
    return {
      artClubFeaturesList,
    };
  },
  methods: {
    onMembershipPlanBtnClick(): void {
      window.open(ART_CLUB_SALES_LANDING_LINK, '_self');
    },
  },
});
