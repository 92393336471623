import { IArtClubFeaturesListItem } from '~/features/main-landing/interfaces/art-club-features-list-item.interface';

export const artClubFeaturesList: IArtClubFeaturesListItem[] = [
  {
    title: 'mainLanding.artClub.list.1',
    icon: 'main-landing/camera',
  },
  {
    title: 'mainLanding.artClub.list.2',
    icon: 'main-landing/money',
  },
  {
    title: 'mainLanding.artClub.list.3',
    icon: 'main-landing/palette',
  },
  {
    title: 'mainLanding.artClub.list.4',
    icon: 'main-landing/star',
  },
  {
    title: 'mainLanding.artClub.list.5',
    icon: 'main-landing/mentor',
  },
  {
    title: 'mainLanding.artClub.list.6',
    icon: 'main-landing/graduation',
  },
];
