












































































import { defineComponent } from '@nuxtjs/composition-api';
import { featuresList } from '~/features/main-landing/config/features-list.config';
import MainLandingPlatformFeaturesSlider from '~/features/main-landing/components/MainLandingPlatformFeaturesSlider.vue';
import UiButton from '~/shared/components/UiButton/index.vue';
import UiAdaptive from '~/shared/components/UiAdaptive.vue';
import { scrollToAnchor } from '~/utils/scroll-to-anchor';

export default defineComponent({
  name: 'MainLandingPlatformFeatures',
  components: { UiAdaptive, UiButton, MainLandingPlatformFeaturesSlider },
  setup() {
    return {
      featuresList,
    };
  },
  data() {
    return {
      activeSlideIndex: 0,
    };
  },
  methods: {
    onSlideChange(newIndex: number): void {
      this.activeSlideIndex = newIndex;
    },

    onListItemClick(newIndex: number): void {
      (this.$refs.slider as InstanceType<typeof MainLandingPlatformFeaturesSlider>).changeSlide(
        newIndex,
      );
    },

    onJoinNowClick(): void {
      scrollToAnchor('registration', false);
    },
  },
});
