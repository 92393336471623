









































import { defineComponent } from '@nuxtjs/composition-api';
import { Splide } from '@splidejs/splide';
import { feedbacksList } from '~/features/main-landing/config/feedbacks-list.config';

export default defineComponent({
  name: 'MainLandingFeedbacks',
  setup() {
    return {
      feedbacksList,
    };
  },
  mounted() {
    this.mountSlider();
  },
  methods: {
    mountSlider(): void {
      if (this.$nuxt.$isServer) return;

      new Splide(this.$refs.slider as HTMLDivElement, {
        type: 'loop',
        autoplay: true,
        perPage: 1,
        pagination: true,
        autoHeight: true,
        arrows: false,
        breakpoints: {
          767: {
            arrows: true,
          },
        },
      }).mount();
    },
  },
});
